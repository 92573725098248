var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('confirm-delete',{ref:"modal",attrs:{"msg":_vm.deleteMsg,"title":_vm.titleMsg},on:{"on-submit":_vm.deleteCategory}}),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}}),_c('b-col',{attrs:{"md":"6"}},[(
              _vm.checkLoginRole() === _vm.RoleEnum.SuperAdmin
                ? true
                : _vm.checkAccess.Add
            )?_c('div',{staticClass:"d-flex align-items-center justify-content-end flex-wrap"},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push('/faq/list')}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Back to FAQ")])]),_c('b-button',{staticClass:"mb-2 ml-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push('add')}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Add Category")])])],1):_vm._e()])],1)],1),_c('b-table',{ref:"refUserListTable",staticClass:"position-relative",attrs:{"items":_vm.items,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(category)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(
              _vm.checkLoginRole() === _vm.RoleEnum.SuperAdmin
                ? true
                : _vm.checkAccess.Edit
            )?_c('b-dropdown-item',{attrs:{"to":{ name: 'category-edit', params: { id: data.item.id } }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Edit")])],1):_vm._e(),(
              _vm.checkLoginRole() === _vm.RoleEnum.SuperAdmin
                ? true
                : _vm.checkAccess.delete
            )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.openModal(data.item.id, data.item.name)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1):_vm._e()],1)]}}])},[_vm._v(". ")]),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}}),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalCategories,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }